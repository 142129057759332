import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 360.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)">

<path d="M5 1800 c0 -993 1 -1399 2 -902 2 496 2 1308 0 1805 -1 496 -2 90 -2
-903z"/>
<path d="M1440 3191 c-49 -15 -106 -50 -142 -87 -40 -39 -88 -125 -88 -156 0
-12 -20 -27 -65 -47 -252 -114 -451 -353 -521 -625 -23 -90 -35 -231 -26 -313
l8 -73 -82 0 -82 0 5 -62 c11 -141 53 -298 111 -413 79 -158 230 -329 369
-419 200 -129 466 -193 695 -168 276 30 494 135 674 325 175 184 276 413 297
675 l5 62 -54 0 -53 0 6 93 c19 290 -117 604 -340 784 -75 62 -181 125 -248
149 -42 15 -48 21 -53 53 -13 84 -116 188 -214 217 -48 15 -161 17 -202 5z
m221 -35 c42 -18 130 -94 146 -127 6 -13 21 -44 32 -69 17 -37 29 -48 66 -64
75 -30 195 -114 262 -182 101 -103 171 -227 215 -381 18 -64 22 -103 22 -213
-1 -118 -4 -147 -28 -225 -15 -49 -42 -118 -61 -152 l-33 -63 44 0 44 0 0 -62
0 -63 41 89 c22 49 48 119 56 155 9 36 19 69 22 73 3 4 21 0 39 -7 29 -13 32
-18 32 -57 -1 -41 -15 -121 -39 -215 -6 -24 -29 -82 -51 -129 -129 -280 -336
-465 -627 -560 -212 -69 -482 -65 -683 12 -117 45 -144 58 -215 105 -253 166
-435 464 -461 755 -7 80 -7 80 66 85 19 2 40 6 46 11 7 5 16 -17 28 -65 22
-93 93 -242 155 -326 148 -199 365 -330 623 -375 21 -4 37 -10 35 -15 -3 -12
58 -103 91 -134 25 -24 33 -27 50 -18 27 14 109 109 124 142 10 22 27 30 105
52 201 57 372 172 497 337 60 77 61 80 36 80 -15 0 -20 -5 -17 -14 15 -38
-158 -216 -279 -288 -82 -49 -196 -97 -251 -105 l-32 -5 24 48 c13 27 38 64
54 83 17 18 31 37 31 41 0 8 64 36 101 44 37 8 139 78 190 131 36 37 50 45 81
45 20 0 40 5 43 10 4 6 -14 10 -45 10 -48 0 -56 -4 -104 -47 -60 -54 -184
-137 -229 -154 -22 -7 -54 -40 -100 -98 -97 -124 -231 -289 -238 -293 -6 -2
-198 248 -223 290 -5 8 8 3 28 -10 19 -13 35 -29 33 -37 -2 -16 54 -38 121
-47 48 -6 134 6 187 26 29 11 29 12 10 26 -17 13 -18 16 -5 24 8 5 18 10 22
10 16 0 119 104 146 148 32 51 47 110 36 144 -5 16 -8 18 -15 7 -5 -8 -9 -26
-9 -40 0 -39 -56 -144 -96 -178 -85 -75 -209 -111 -316 -92 -88 15 -178 62
-229 119 -39 44 -99 152 -99 179 0 6 -5 15 -12 19 -8 5 -9 -5 -4 -37 8 -54 63
-169 80 -169 7 0 21 -7 32 -15 13 -11 1 -9 -41 6 -83 30 -200 101 -273 165
-40 36 -69 54 -86 54 -22 0 -26 4 -26 30 0 16 -14 52 -30 78 -167 272 -184
573 -47 847 42 86 82 141 152 210 72 72 131 114 238 170 77 39 80 43 103 100
36 89 103 150 197 181 51 17 167 12 218 -10z m800 -1091 c4 11 6 -43 4 -120
-3 -138 -16 -203 -55 -276 -12 -21 -13 -21 -23 -3 -6 10 -19 26 -29 34 -19 15
-18 17 9 75 15 33 28 65 29 70 1 6 9 36 19 68 9 32 18 73 19 90 7 80 8 83 14
62 5 -20 5 -20 13 0z m-1796 -197 c22 -70 82 -195 111 -234 13 -17 29 -44 34
-61 6 -17 22 -35 34 -41 13 -6 49 -34 79 -62 86 -80 226 -160 281 -160 29 0
76 -19 76 -32 0 -4 5 -8 12 -8 20 0 68 -62 79 -104 6 -21 8 -40 6 -42 -9 -9
-187 50 -260 87 -201 101 -345 256 -438 470 -44 100 -64 191 -48 220 5 10 11
19 13 19 2 0 11 -24 21 -52z"/>
<path d="M1452 3027 c-8 -10 -2 -12 31 -8 33 3 38 5 22 12 -29 11 -41 11 -53
-4z"/>
<path d="M1544 3032 c3 -5 24 -9 48 -10 32 0 38 2 23 8 -26 11 -78 12 -71 2z"/>
<path d="M1386 2995 c-14 -13 -26 -21 -26 -18 0 3 -9 -6 -20 -20 -22 -28 -25
-37 -10 -37 6 0 10 5 10 11 0 14 38 47 68 59 12 5 22 14 22 20 0 17 -15 12
-44 -15z"/>
<path d="M1205 2841 c16 -106 93 -208 193 -254 68 -31 208 -31 274 0 102 47
180 149 194 256 l6 43 -68 -7 c-46 -4 -103 -21 -168 -48 -102 -42 -125 -47
-141 -32 -23 20 -195 79 -243 83 l-54 5 7 -46z m204 -21 c53 -22 108 -40 122
-40 13 0 68 18 122 40 110 45 162 51 170 20 21 -81 -105 -218 -221 -240 -142
-26 -273 38 -335 165 -52 106 -16 120 142 55z"/>
<path d="M1240 2448 c0 -3 -18 -59 -41 -124 -22 -66 -42 -125 -45 -133 -3 -10
-92 221 -94 244 0 0 -25 0 -55 0 -33 0 -53 -4 -51 -10 3 -5 45 -112 94 -237
48 -125 91 -230 94 -233 4 -4 46 109 95 255 2 8 25 -41 49 -110 24 -69 47
-132 50 -140 4 -10 11 0 21 28 l15 43 36 -42 c20 -23 54 -50 76 -60 59 -27
156 -25 228 5 l57 23 36 -24 c20 -14 39 -23 41 -20 3 3 -15 18 -40 33 l-45 29
-3 75 c-1 41 -2 87 -2 103 l-1 27 -95 0 -96 0 1 -47 1 -48 42 3 42 4 0 -42 0
-42 -60 4 c-51 4 -66 9 -91 35 -79 78 -44 225 61 253 42 11 96 -1 133 -30 14
-11 28 -20 31 -20 3 0 6 25 6 55 0 75 -23 90 -136 90 -76 0 -81 1 -72 18 12
24 12 24 -44 19 -31 -3 -49 -9 -53 -20 -4 -10 -24 -65 -45 -124 l-37 -106 -43
126 c-36 108 -60 163 -60 140z m318 -80 c20 0 42 -2 47 -3 6 -2 22 -3 37 -4
17 -1 32 -9 39 -22 11 -21 10 -21 -27 -13 -93 20 -158 -2 -201 -67 -36 -54
-38 -147 -4 -202 38 -62 133 -94 196 -65 49 22 45 97 -6 124 -17 8 -28 18 -24
22 10 10 79 12 95 2 19 -12 21 -140 3 -161 -18 -22 -99 -42 -146 -37 -53 7
-126 38 -132 58 -6 19 -61 51 -78 45 -7 -3 -23 23 -41 72 -17 42 -40 88 -52
102 l-21 25 -25 -27 c-14 -14 -33 -54 -43 -87 -10 -34 -22 -63 -26 -65 -12 -8
-27 19 -58 109 -17 49 -35 94 -40 100 -17 23 -40 106 -30 112 13 8 12 10 39
-61 12 -33 26 -68 31 -77 5 -10 9 -24 9 -31 0 -8 9 -22 21 -33 18 -17 23 -17
46 -4 15 8 27 22 29 30 7 36 40 114 51 117 6 2 19 -20 28 -49 20 -60 50 -108
68 -108 7 0 18 10 26 21 30 49 51 90 51 101 0 7 7 31 16 55 17 44 47 59 72 36
7 -7 30 -14 50 -15z"/>
<path d="M1770 2175 l0 -45 70 0 70 0 0 -187 0 -188 55 0 55 0 0 189 0 188 70
-4 70 -4 0 48 0 48 -195 0 -195 0 0 -45z m344 0 c4 -12 -4 -15 -38 -15 -27 0
-52 -7 -65 -17 -19 -17 -21 -29 -22 -168 -1 -156 -9 -196 -35 -174 -11 9 -14
44 -14 165 0 145 -1 154 -22 174 -16 14 -35 20 -71 20 -43 0 -48 2 -37 15 10
12 41 15 155 15 118 0 144 -3 149 -15z"/>
<path d="M1291 1905 c-41 -47 -79 -123 -87 -177 -7 -39 -6 -42 9 -29 10 7 17
26 17 42 0 15 5 31 11 34 16 10 22 -1 11 -22 -5 -10 -6 -29 -3 -43 6 -21 10
-16 30 38 16 46 39 80 83 127 36 39 54 65 45 65 -20 0 -89 -65 -115 -108 -12
-21 -22 -29 -27 -22 -4 6 12 35 34 64 23 29 41 56 41 59 0 16 -20 4 -49 -28z"/>
<path d="M1477 1825 c-8 -7 -27 -17 -42 -21 -20 -5 -23 -9 -13 -15 10 -7 9 -9
-4 -9 -10 0 -18 -5 -18 -11 0 -5 22 2 50 18 27 15 50 34 50 41 0 16 -5 15 -23
-3z"/>
<path d="M1757 1809 c16 -28 53 -58 53 -44 0 12 -52 75 -62 75 -5 0 -1 -14 9
-31z"/>
<path d="M1565 1791 c3 -18 5 -41 5 -50 1 -14 2 -14 11 1 7 12 5 27 -6 50
l-15 33 5 -34z"/>
<path d="M1675 1698 c-27 -5 -54 -14 -60 -20 -7 -7 -3 -8 12 -4 11 4 98 7 192
7 157 1 197 7 155 23 -24 9 -242 5 -299 -6z"/>
<path d="M840 1671 c0 -5 6 -11 14 -14 8 -3 18 -26 22 -52 7 -51 25 -56 35 -9
l7 29 1 -32 c2 -47 27 -43 35 6 3 22 9 46 12 55 3 9 2 16 -4 16 -5 0 -13 -17
-18 -37 l-8 -38 -5 33 c-8 42 -24 42 -32 0 l-6 -33 -2 32 c-1 21 -8 36 -20 43
-24 12 -31 13 -31 1z"/>
<path d="M987 1663 c-2 -5 -7 -25 -11 -46 -4 -21 -10 -45 -13 -53 -3 -8 0 -14
6 -14 6 0 11 7 11 15 0 8 7 15 15 15 8 0 15 -7 15 -15 0 -10 8 -14 23 -11 13
2 21 9 19 19 -4 26 16 28 22 2 4 -14 11 -25 17 -25 6 0 7 8 2 23 -4 12 -7 39
-6 60 0 20 -1 37 -3 37 -2 0 -10 -12 -19 -27 -12 -22 -15 -23 -13 -8 2 11 -2
20 -7 20 -6 0 -9 -20 -7 -45 4 -54 -13 -38 -23 22 -6 35 -18 48 -28 31z"/>
<path d="M1110 1615 l0 -55 35 0 c19 0 35 5 35 10 0 14 28 12 33 -2 2 -7 7
-10 11 -6 10 9 -16 100 -29 102 -5 0 -14 -19 -20 -44 -8 -35 -14 -45 -27 -42
-14 3 -18 14 -18 48 0 24 -4 44 -10 44 -5 0 -10 -25 -10 -55z"/>
<path d="M1258 1662 c-14 -3 -18 -14 -18 -53 0 -47 1 -49 29 -49 30 0 47 22
34 43 -4 7 -6 22 -5 33 3 23 -12 33 -40 26z"/>
<path d="M1356 1648 c-20 -28 -20 -48 -1 -75 20 -28 55 -20 55 13 0 14 -6 24
-15 24 -9 0 -12 -6 -8 -16 4 -12 2 -15 -8 -12 -8 3 -14 16 -14 29 0 19 5 24
23 22 24 -3 28 7 10 25 -17 17 -25 15 -42 -10z"/>
<path d="M1420 1616 c0 -49 2 -54 25 -58 17 -4 25 -1 25 8 0 8 -7 14 -15 14
-11 0 -15 12 -15 45 0 25 -4 45 -10 45 -5 0 -10 -24 -10 -54z"/>
<path d="M1492 1658 c-18 -18 -15 -83 4 -99 32 -26 58 46 34 92 -12 22 -21 24
-38 7z m28 -48 c0 -16 -4 -30 -10 -30 -5 0 -10 14 -10 30 0 17 5 30 10 30 6 0
10 -13 10 -30z"/>
<path d="M1550 1615 c0 -54 0 -55 29 -55 29 0 30 1 29 49 0 42 -3 50 -21 55
-36 9 -37 8 -37 -49z"/>
<path d="M1988 1663 c-14 -3 -18 -15 -18 -54 0 -48 1 -49 31 -49 17 0 28 4 24
10 -3 6 -13 10 -21 10 -8 0 -14 5 -14 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6
-7 10 -15 10 -22 0 -18 17 6 23 12 3 18 10 14 16 -6 11 -9 12 -37 4z"/>
<path d="M2041 1651 c-15 -27 -14 -63 3 -86 17 -23 46 -13 46 17 0 17 -3 19
-12 10 -19 -19 -28 -14 -28 18 0 32 8 37 29 18 10 -11 12 -8 9 12 -4 31 -33
38 -47 11z"/>
<path d="M2120 1654 c-18 -21 -17 -72 2 -90 33 -33 63 38 38 87 -13 23 -23 24
-40 3z m30 -44 c0 -31 -16 -41 -26 -15 -8 19 2 45 16 45 5 0 10 -13 10 -30z"/>
<path d="M2180 1615 c0 -58 19 -78 21 -22 2 27 2 28 6 7 8 -43 21 -44 30 -4
l7 39 7 -40 6 -40 2 48 c0 26 1 50 2 54 0 4 -4 9 -10 11 -5 2 -14 -12 -19 -30
l-9 -33 -7 33 c-13 55 -36 40 -36 -23z"/>
<path d="M2293 1663 c-21 -7 -15 -49 10 -67 l22 -17 -22 5 c-15 3 -23 -1 -23
-10 0 -8 10 -14 24 -14 41 0 45 53 4 63 -11 3 -17 10 -14 16 4 6 13 8 21 4 8
-3 15 -1 15 5 0 12 -21 21 -37 15z"/>
<path d="M1631 1610 c-14 -65 -14 -69 5 -45 14 19 16 19 25 2 18 -31 26 -9 15
43 -5 27 -15 50 -22 50 -7 0 -17 -23 -23 -50z"/>
<path d="M1700 1611 c0 -56 7 -61 76 -61 l44 0 0 44 c0 25 5 48 10 51 6 4 10
-12 10 -39 0 -44 1 -46 30 -46 17 0 30 5 30 10 0 6 -9 10 -20 10 -11 0 -20 5
-20 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6
9 10 20 10 11 0 20 5 20 10 0 6 -27 10 -60 10 -33 0 -60 -4 -60 -10 0 -5 5
-10 11 -10 6 0 9 -16 7 -37 -2 -31 -7 -39 -25 -41 -13 -2 -23 1 -23 7 0 6 -7
11 -15 11 -11 0 -15 11 -15 40 0 22 -4 40 -10 40 -5 0 -10 -22 -10 -49z"/>
<path d="M1910 1610 c0 -47 2 -50 25 -50 14 0 25 5 25 10 0 6 -7 10 -15 10
-11 0 -15 11 -15 40 0 22 -4 40 -10 40 -5 0 -10 -22 -10 -50z"/>
<path d="M1954 1483 c-8 -32 -18 -65 -20 -75 -3 -9 -1 -19 4 -22 11 -6 46 72
55 122 5 24 3 32 -8 32 -9 0 -20 -21 -31 -57z"/>
<path d="M1220 1520 c0 -25 45 -96 88 -139 26 -25 73 -58 104 -73 71 -35 100
-36 35 -2 -65 34 -123 94 -156 162 -19 41 -33 58 -49 60 -12 2 -22 -2 -22 -8z"/>
<path d="M1814 1484 c-4 -16 -13 -42 -21 -59 l-14 -30 20 25 c21 26 37 77 27
87 -3 3 -8 -8 -12 -23z"/>
<path d="M1435 1490 c-6 -9 -4 -11 5 -5 10 6 12 4 8 -7 -9 -23 104 -128 138
-128 4 0 -12 14 -34 30 -23 17 -42 35 -42 40 0 6 -7 10 -16 10 -8 0 -13 3 -11
8 3 4 -5 20 -18 36 -16 20 -25 25 -30 16z"/>
<path d="M1610 1481 c-30 -9 -90 -40 -90 -46 0 -5 103 36 115 47 10 9 9 9 -25
-1z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
